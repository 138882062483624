import React, { useState, useEffect } from "react";
import { navigate, Link } from "gatsby";
import * as style from "./less/footer.module.less";
import instagram from "../images/home/instagram.png";
import facebook from "../images/home/facebook.png";
import twitter from "../images/home/Twitter.png";
import choose from "../images/home/foot_choose.png";
import toTopImg from "../images/home/toTop.png";
export default function Footer() {
  const [color, setColor] = useState("black");
  const [offset, setOffest] = useState(false);
  const footList = [
    {
      id: 0,
      h6: "Product",
      span1: "Collaborate",
      span2: "Comply",
      span3: "Govern",
    },
    {
      id: 1,
      h6: "Platform",
      span1: "Overview",
      span2: "Benefits",
      span3: "Resources",
    },
    {
      id: 2,
      h6: "Solution",
      span1: "Healthcare",
      span2: "Smart Cities",
      span3: "Financial Services",
    },
  ];
  const toTop = (e) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", getScroll);
    }
  }, []);
  const getScroll = (e) => {
    let scrollTop =
      (e.srcElement ? e.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (e.srcElement ? e.srcElement.body.scrollTop : 0);

    if (scrollTop > 500) {
      setOffest(true);
    } else {
      setOffest(false);
    }
  };
  return (
    <footer className={style.footer}>
      <div
        onClick={toTop}
        style={{ display: offset ? "flex" : "none" }}
        className={style.to_top}
      >
        <svg
          width="16"
          height="7"
          stroke="blue"
          viewBox="0 0 16 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.806641 5.88385L7.99998 2.11548L15.1933 5.88385"
            stroke="black"
            stroke-width="2"
          />
        </svg>
      </div>
      <div className={style.content}>
        <div className={style.contact_us}>
          <h5>Contact us</h5>
          {/* <div className={style.number}>1-416-628-7095</div> */}
          <div className={style.email}>
            info@resiliencecorporateservices.com
          </div>
        </div>
        <ul className={style.content_list}>
          {/* {
                        footList.map(item => {
                            return (
                                <li key={item.id}>
                                    <h6>{item.h6}</h6>
                                    <div>{item.span1}</div>
                                    <div>{item.span2}</div>
                                    <div>{item.span3}</div>
                                </li>
                            )
                        })
                    } */}
          <li>
            <h6>Product</h6>

            <div>
              <Link to={"/productList?id=" + 0}>Collaborate</Link>
            </div>
            <div>
              <Link to={"/productList?id=" + 2}>Comply</Link>
            </div>
            <div>
              <Link to={"/productList?id=" + 3}>Govern</Link>
            </div>
          </li>
          <li>
            <h6>Platform</h6>
            <div>
              <Link to={"/#platform"}>Overview</Link>
            </div>
            <div>
              <Link to={"/solutionsDetail?id=0/#benefits"}>Benefits</Link>
            </div>
            <div>
              <Link to={"/resources"}>Resources</Link>
            </div>
          </li>
          <li>
            <h6>Solution</h6>
            <div>
              <Link to={"/solutionsDetail?id=" + 0}>Healthcare</Link>
            </div>
            <div>
              <Link to={"/solutionsDetail?id=" + 1}>Smart Cities</Link>
            </div>
            <div>
              <Link to={"/solutionsDetail?id=" + 2}>Financial Services</Link>
            </div>
          </li>
        </ul>
      </div>
      <div
        onClick={() => navigate("/contactUs")}
        className={style.footer_botton}
      >
        Talk to an Expert
      </div>
      <ul>
        <li>
          <span>© 2022 Sightline Innovation Inc.</span>
          <span>Privacy Policy</span>
        </li>
        <li>
          <div>
            <img src={instagram} alt="" />
          </div>
          <div>
            <img src={twitter} alt="" />
          </div>
          <div>
            <img src={facebook} alt="" />
          </div>
        </li>
      </ul>
    </footer>
  );
}
